.main-card, .tableComponent{
  border-radius: 20px !important;
}

.input-lb > .MuiInputBase-sizeSmall, .submitButton{
  border-radius: 50px !important;
}

label.Mui-focused{
  color: #c2608e !important;
}

.Mui-focused fieldset{
  border-color: #c2608e!important;
}

.header-link{
  text-decoration: none;
   
}
.header-link:hover{
  text-decoration: none;
}
